jQuery(document).ready(function($) {
  $('a[href^="#"]').bind('click.smoothscroll',function (e) {
    e.preventDefault();
    var target = this.hash,
        $target = $(target);

    $('html, body').stop().animate( {
      'scrollTop': $target.offset().top-40
    }, 400, 'swing', function () {
      window.location.hash = target;
    } );
  } );
} );

jQuery(document).ready(function ($) {
  $('.open-menu').click(function () {
      $('.we-mobile-menu').toggleClass('opened');
      $('.body-wrapper').toggleClass('mobile-menu-opened');
      $('a.nav-link').click(function(){
        $('.we-mobile-menu').removeClass('opened');
        $('.body-wrapper').removeClass('mobile-menu-opened');
      });
  })
});

jQuery(document).ready(function ($) {
  $('.body-wrapper').removeClass('fade-out').delay(400);
});
